import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

import { getJSON } from '../api';

const AttachmentJSONView = ({ storageRef }) => {
  const [dataSource, setDataSource] = useState();

  useEffect(() => {
    getJSON({ storageRef }).then(({ json }) => {
      setDataSource(json);
    });
  }, []);

  if (!dataSource) return (<Spin />);

  return (
    <div>
      <pre>{JSON.stringify(dataSource, null, 2)}</pre>
    </div>
  );
};

AttachmentJSONView.propTypes = {
  storageRef: PropTypes.string.isRequired,
};

export default AttachmentJSONView;
