/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

class CreateCaseForm extends React.Component {
  render() {
    const { form, onSubmit } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form onSubmit={onSubmit}>
        <Form.Item>
          {
            getFieldDecorator('name', {
              rules: [{ required: true, message: 'Please name the Case' }],
            })(
              <Input placeholder="Case Name" autoFocus />,
            )
          }
        </Form.Item>
      </Form>
    );
  }
}

CreateCaseForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Form.create({ name: 'createCase' })(CreateCaseForm);
