import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Icon } from 'antd';

export default function Status({ value, onErrorClick, createdAt }) {
  const ago = moment().diff(moment(createdAt), 'minutes');

  const handleErrorClick = (evt) => {
    evt.preventDefault();
    onErrorClick();
  };

  if (value === 'Error') {
    return (
      <a href="error" onClick={handleErrorClick}>{value}</a>
    );
  }

  if (['Pending', 'Processing'].includes(value) && ago > 60) {
    return (
      <span>Error (Timeout)</span>
    );
  }

  if (value === 'Processing') {
    return (
      <span>
        <Icon type="loading" />
        <span className="progress-loading-label">{value}</span>
      </span>
    );
  }

  return (
    <span>{value}</span>
  );
}

Status.propTypes = {
  value: PropTypes.string,
  onErrorClick: PropTypes.func,
  createdAt: PropTypes.string.isRequired,
};

Status.defaultProps = {
  value: '',
  onErrorClick: () => {},
};
