/* eslint-disable react/prop-types */
import React from 'react';

import { Redirect } from 'react-router-dom';

import {
  Button,
} from 'antd';

import { useAuthStrategy } from '../AuthStrategyContext';
import { useAuth } from '../AuthContext';
import LocalLogin from './Local';

import './styles.css';

const Login = () => {
  const auth = useAuth();
  const authStrategy = useAuthStrategy();

  if (auth.loading || authStrategy.loading) return (<div>Loading</div>);
  if (auth.isAuthenticated) return (<Redirect to="/" />);

  if (authStrategy.strategy === 'local') {
    return <LocalLogin />;
  }

  return (
    <div className="layout">
      <div className="auth-wrapper">
        <Button className="login" type="primary" href="/api/login">
          Login with Active Directory
        </Button>
      </div>
    </div>
  );
};

export default Login;
