/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { Input, Modal } from 'antd';

const CaseEditModal = ({
  note,
  setNote,
  visible,
  onOk,
  onCancel,
  // eslint-disable-next-line arrow-body-style
}) => {
  return (
    <Modal
      title="Edit Case Notes"
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      okText="Update"
    >
      <Input.TextArea
        placeholder="Enter Case Notes"
        rows={6}
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
    </Modal>
  );
};

CaseEditModal.propTypes = {
  note: PropTypes.string,
  setNote: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

CaseEditModal.defaultProps = {
  note: '',
};

export default CaseEditModal;
