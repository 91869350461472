import React from 'react';
import PropTypes from 'prop-types';

import { getStrategy } from '../api';

const AuthStrategyContext = React.createContext();

export const useAuthStrategy = () => {
  const context = React.useContext(AuthStrategyContext);
  if (!context) throw new Error('useAuthStrategy must be called inside the AuthStrategyProvider');
  return context;
};

export const AuthStrategyProvider = ({ children }) => {
  const [loading, setLoading] = React.useState(true);
  const [strategy, setStrategy] = React.useState(undefined);

  const load = async () => {
    const data = await getStrategy();
    setStrategy(data.strategy);
    setLoading(false);
  };

  React.useEffect(() => {
    load();
  }, []);

  const value = React.useMemo(() => ({
    loading,
    strategy,
  }), [loading, strategy]);

  return (
    <AuthStrategyContext.Provider value={value}>
      {children}
    </AuthStrategyContext.Provider>
  );
};

AuthStrategyProvider.propTypes = {
  children: PropTypes.node,
};

AuthStrategyProvider.defaultProps = {
  children: null,
};
