import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';

import {
  PageHeader, Table, Select, DatePicker, Button, Divider, Input,
} from 'antd';

import White from './White';
import Content from './Content';
import CaseItemAdd from './CaseItemAdd';
import GapsBanner from './GapsBanner';
import UserSelect from './UserSelect';
import UserName from './UserName';
import {
  searchGapsForBanner, searchUnknown, getUnknownFields, getUnknownObjects,
} from '../api';

const { Option } = Select;
const { RangePicker } = DatePicker;

const routes = [{ breadcrumbName: 'QPS Workplace' }, { breadcrumbName: 'Unknown' }];

const Unknown = ({ history, location }) => {
  const { startDate, endDate, ...parsedQuery } = qs.parse(location.search);
  const { page: initialPage, ...initialFilters } = {
    page: 1,
    ...parsedQuery,
    dateRange: startDate && endDate
      ? [moment(startDate), moment(endDate)]
      : undefined,
  };
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [objectTypes, setObjectTypes] = useState([]);
  const [fieldTypes, setFieldTypes] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [caseItem, setCaseItem] = useState();
  const [gapDataSource, setGaps] = useState([]);
  const [pagination, setPagination] = useState({ page: initialPage });
  const [total, setTotal] = useState(0);

  const handleCaseItemModelOpen = (item) => () => setCaseItem({ id: item.id, type: 'unknown' });
  const handleCaseItemModelClose = () => setCaseItem(null);

  const doSearch = async () => {
    const { dateRange, ...params } = { ...filters, ...pagination };

    const parsedDateRange = dateRange && dateRange[0] && dateRange[1]
      ? [dateRange[0].toISOString(), dateRange[1].toISOString()]
      : [];

    history.push({
      pathname: '/unknown',
      search: qs.stringify({
        ...params,
        startDate: parsedDateRange[0],
        endDate: parsedDateRange[1],
      }),
    });

    /* eslint-disable-next-line */
    params.startDate = parsedDateRange[0];
    /* eslint-disable-next-line */
    params.endDate = parsedDateRange[1];

    const [
      { results, total: totalCount },
      { gaps },
    ] = await Promise.all([
      searchUnknown(params),
      searchGapsForBanner(params),
    ]);
    setGaps(gaps);
    setDataSource(results);
    setTotal(totalCount);
    setLoading(false);
  };

  const handleChange = (key) => (value) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleInputChange = (key) => (evt) => {
    setFilters({ ...filters, [key]: evt.target.value });
  };

  const handleReset = () => {
    setFilters({});
    doSearch();
  };

  const handleFilter = () => {
    doSearch();
  };

  const handleTableChange = ({ current }) => {
    setPagination({ page: current });
  };

  useEffect(() => {
    doSearch();
  }, [pagination]);

  useEffect(() => {
    getUnknownObjects().then(
      ({ events: apiUnknownObjects }) => setObjectTypes(apiUnknownObjects),
    );
    getUnknownFields().then(
      ({ events: apiUnknownFields }) => setFieldTypes(apiUnknownFields),
    );
  }, []);

  const columns = [
    { title: 'Timestamp', key: 'timestamp', dataIndex: 'time' },
    {
      title: 'Actor',
      key: 'actor',
      dataIndex: 'userId',
      render: (userId) => (
        <UserName
          id={userId}
        />
      ),
    },
    { title: 'Object', key: 'object', dataIndex: 'object' },
    { title: 'Field', key: 'field', dataIndex: 'field' },
    { title: 'Message', key: 'message', dataIndex: 'message' },
    {
      title: '',
      key: 'options',
      dataIndex: 'id',
      render: (id, item) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Link to={`/unknown/${id}`}>Details</Link>
          <Divider type="vertical" />
          <Button type="link" onClick={handleCaseItemModelOpen(item)}>Add to Case</Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <White>
        <PageHeader
          title="Unknown"
          subTitle="Explore all Workplace Unknown events"
          breadcrumb={{ routes }}
        />
      </White>
      <Content>
        <div className="table-fitlers">
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            onChange={handleChange('dateRange')}
            value={filters.dateRange}
          />
          <Select placeholder="Object" style={{ minWidth: 300 }} onChange={handleChange('object')} value={filters.object}>
            {
              objectTypes.map((e) => (
                <Option value={e.object} key={e.object}>{e.object}</Option>
              ))
            }
          </Select>
          <Select placeholder="Field" style={{ minWidth: 300 }} onChange={handleChange('field')} value={filters.field}>
            {
              fieldTypes.map((e) => (
                <Option value={e.field} key={e.field}>{e.field}</Option>
              ))
            }
          </Select>
          <UserSelect placeholder="Actor" style={{ minWidth: 300 }} onChange={handleChange('actor')} value={filters.actor} />
          <Input placeholder="Message" style={{ width: 300 }} onChange={handleInputChange('message')} value={filters.message} />
          <Button type="primary" onClick={handleFilter}>Filter</Button>
          <Button onClick={handleReset}>Reset</Button>
        </div>
        <GapsBanner dataSource={gapDataSource} dateRange={filters.dateRange} />
        <Table
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          rowKey="id"
          onChange={handleTableChange}
          pagination={{ total, defaultCurrent: pagination.page }}
        />
      </Content>
      <CaseItemAdd
        visible={Boolean(caseItem)}
        item={caseItem}
        onClose={handleCaseItemModelClose}
      />
    </>
  );
};

Unknown.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
};

export default withRouter(Unknown);
