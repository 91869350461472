import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { UserProvider } from './components/UserContext';

import Cases from './components/Cases';
import Gaps from './components/GapsList';
import Home from './components/Home';
import Search from './components/Search';
import Security from './components/Security';
import SecurityDetail from './components/SecurityDetail';
import Unknown from './components/Unknown';
import UnknownDetail from './components/UnknownDetail';
import WorkChat from './components/WorkChat';
import WorkChatDetail from './components/WorkChatDetail';
import PostDetail from './components/PostDetail';
import CommentDetail from './components/CommentDetail';
import CaseDetail from './components/CaseDetail';
import Login from './components/Login';
import Logout from './components/Logout';
import PrivateRoute from './components/PrivateRoute';
import CaseExports from './components/CaseExports';

import { AuthProvider } from './components/AuthContext';
import { AuthStrategyProvider } from './components/AuthStrategyContext';

import 'antd/dist/antd.css';

const privateRoutes = [
  ['/gaps', Gaps],
  ['/cases/:id', CaseDetail],
  ['/cases', Cases],
  ['/case-exports', CaseExports],
  ['/post/:id', PostDetail],
  ['/comment/:id', CommentDetail],
  ['/workchat/:id', WorkChatDetail],
  ['/workchat', WorkChat],
  ['/security/:id', SecurityDetail],
  ['/security', Security],
  ['/unknown/:id', UnknownDetail],
  ['/unknown', Unknown],
  ['/search', Search],
  ['/', Home],
];

const App = () => (
  <AuthProvider>
    <AuthStrategyProvider>
      <UserProvider>
        <BrowserRouter>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />

            {privateRoutes.map(([path, component]) => (
              <PrivateRoute key={path} path={path} component={component} />
            ))}

            <PrivateRoute key="/gaps/:id" path="/gaps" component={Gaps} />
          </Switch>
        </BrowserRouter>
      </UserProvider>
    </AuthStrategyProvider>
  </AuthProvider>
);

export default App;
