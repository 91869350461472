import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import CaseItemAddForm from './CaseItemAddForm';
import { createCaseItem } from '../api';

const CaseItemAdd = ({
  visible, item, onClose,
}) => {
  const ref = createRef();

  const close = () => {
    const { form } = ref.current.props;
    onClose();
    setTimeout(() => {
      form.resetFields();
    }, 250);
  };

  const handleSubmit = (evt) => {
    const { form } = ref.current.props;
    if (evt) evt.preventDefault();
    form.validateFields(async (err, { caseId, notes }) => {
      if (!err) {
        await createCaseItem(caseId, notes, item);
        close();
      }
    });
  };

  return (
    <Modal
      title="Add Item to Case"
      visible={visible}
      onOk={handleSubmit}
      onCancel={close}
    >
      <CaseItemAddForm wrappedComponentRef={ref} onSubmit={handleSubmit} />
    </Modal>
  );
};

CaseItemAdd.propTypes = {
  visible: PropTypes.bool.isRequired,
  item: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
};

CaseItemAdd.defaultProps = {
  item: {},
};

export default CaseItemAdd;
