const apiEndpoint = '/api';

export const get = async (path, params = {}) => {
  const url = new URL(`${apiEndpoint}${path}`, window.location.origin);

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      url.searchParams.append(key, value);
    }
  });

  const response = await fetch(url, {
    cache: 'no-cache',
    credentials: 'same-origin',
    referrerPolicy: 'no-referrer',
    method: 'GET',
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return response.json();
};

export const post = async (path, { params = {}, body = {} }) => {
  const url = new URL(`${apiEndpoint}${path}`, window.location.origin);

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      url.searchParams.append(key, value);
    }
  });

  const response = await fetch(url, {
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(body),
    method: 'POST',
  });

  if (!response.ok) {
    const error = await response.json();
    console.error(error);
    throw Error(error.message);
  }

  return response.json();
};

export const destroy = async (path) => {
  const url = new URL(`${apiEndpoint}${path}`, window.location.origin);

  const response = await fetch(url, {
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer',
    method: 'DELETE',
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }
};

export const search = async (params = {}) => get('/search', params);
export const getJSON = async (params) => get('/json', params);
