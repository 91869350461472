import React from 'react';
import PropTypes from 'prop-types';
import WorkplaceLink from './WorkplaceLink';

import { useUsers } from './UserContext';

const UserName = ({ id }) => {
  const { getUser } = useUsers();

  if (!id) {
    return null;
  }

  const user = getUser(id.toString());

  if (!user) {
    return null;
  }

  const { name, email, externalId } = user;

  if (name) {
    return (
      <span>
        <WorkplaceLink href={`/${externalId}`}>{`${name} (${externalId})`}</WorkplaceLink>
      </span>
    );
  }

  if (email) {
    return (
      <span>
        <WorkplaceLink href={`/${externalId}`}>{`${email} (${externalId})`}</WorkplaceLink>
      </span>
    );
  }

  return (<WorkplaceLink href={`/${externalId}`}>{externalId}</WorkplaceLink>);
};

UserName.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

UserName.defaultProps = {
  id: null,
};

export default UserName;
