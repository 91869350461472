import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Collapse, PageHeader, Descriptions, Button,
} from 'antd';

import White from './White';
import Content from './Content';
import AttachmentJSONView from './AttachmentJSONView';
import CaseItemAdd from './CaseItemAdd';
import Username from './UserName';

import { getSecurity } from '../api';

const { Panel } = Collapse;

const routes = [{ breadcrumbName: 'QPS Workplace' }, { breadcrumbName: 'Security Detail' }];

const SecurityDetail = ({ match: { params: { id } } }) => {
  const [dataSource, setDataSource] = useState();
  const [caseItem, setCaseItem] = useState();

  const handleCaseItemModelOpen = (item) => () => setCaseItem({ id: item.id, type: 'security' });
  const handleCaseItemModelClose = () => setCaseItem(null);

  useEffect(() => {
    getSecurity(id).then(({ security }) => setDataSource(security));
  }, []);

  if (!dataSource) {
    return null;
  }

  const {
    createdAt: processedAt,
    time,
    processedStorageRef,
    actorId,
    targetId,
    ip,
    field,
  } = dataSource;

  return (
    <>
      <White>
        <PageHeader
          title="Security Detail"
          breadcrumb={{ routes }}
          extra={[
            <Button
              type="primary"
              onClick={handleCaseItemModelOpen(dataSource)}
              key="case"
            >
              Add to Case
            </Button>,
          ]}
        />
      </White>
      <Content>
        <Descriptions title="Message Information" bordered>
          <Descriptions.Item label="Created Time">{time}</Descriptions.Item>
          <Descriptions.Item label="Processed Time">{processedAt}</Descriptions.Item>
          <Descriptions.Item label="Actor">
            <Username id={actorId} />
          </Descriptions.Item>
          <Descriptions.Item label="Target">
            <Username id={targetId} />
          </Descriptions.Item>
          <Descriptions.Item label="IP">{ ip }</Descriptions.Item>
          <Descriptions.Item label="Field" span={3}>{field}</Descriptions.Item>
          <Descriptions.Item label="Events" span={3}>
            <Collapse>
              <Panel header="create">
                <AttachmentJSONView
                  storageRef={processedStorageRef}
                />
              </Panel>
            </Collapse>
          </Descriptions.Item>
        </Descriptions>
        <CaseItemAdd
          visible={Boolean(caseItem)}
          item={caseItem}
          onClose={handleCaseItemModelClose}
        />
      </Content>
    </>
  );
};

SecurityDetail.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
};

export default withRouter(SecurityDetail);
