import React from 'react';
import {
  Button, Icon, Tag, Typography,
} from 'antd';
import PropTypes from 'prop-types';

const ErrorTag = ({ errorMessage }) => {
  if (!errorMessage) return null;
  return (
    <div style={{ paddingBottom: 10 }}>
      <Tag color="red">Error</Tag>
      <Typography.Text code>
        {errorMessage}
      </Typography.Text>
    </div>
  );
};

ErrorTag.propTypes = {
  errorMessage: PropTypes.string,
};

ErrorTag.defaultProps = {
  errorMessage: '',
};

const AttachmentDownload = ({
  name, mimeType, url, errorMessage,
}) => {
  const handleClick = async () => {
    window.location = url;
  };

  if (mimeType && mimeType.startsWith('image')) {
    return (
      <>
        <ErrorTag errorMessage={errorMessage} />
        <img alt={name} src={url} />
      </>
    );
  }

  return (
    <>
      <ErrorTag errorMessage={errorMessage} />
      <Button shape="round" onClick={handleClick}>
        {`${name} (${mimeType})`}
        <Icon type="download" />
      </Button>
    </>
  );
};

AttachmentDownload.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  mimeType: PropTypes.string,
  errorMessage: PropTypes.string,
};

AttachmentDownload.defaultProps = {
  errorMessage: '',
  mimeType: '',
  name: '',
  url: '',
};

export default AttachmentDownload;
