import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export default function TableDateTime({ value }) {
  return (
    <span>{moment(value).format('YYYY/MM/DD, HH:mm:ss')}</span>
  );
}

TableDateTime.propTypes = {
  value: PropTypes.string.isRequired,
};
