import React, { useEffect, useState } from 'react';

import { Card } from 'antd';
import Charts from 'ant-design-pro/lib/Charts';

import { getStats } from '../api';

import 'ant-design-pro/dist/ant-design-pro.css';

const headerCardStyle = {
  width: '100%',
};

const cardStyle = {
  width: '50%',
};

const Home = () => {
  const [{
    posts, comments, chats, total,
  }, setDataSource] = useState({
    posts: 0,
    comments: 0,
    chats: 0,
  });

  useEffect(() => {
    getStats().then((dataSource) => setDataSource({
      ...dataSource,
      total: Object.values(dataSource).reduce((a, b) => a + b),
    }));
  }, []);

  const pieData = [{
    x: 'Posts',
    y: posts,
  }, {
    x: 'Comments',
    y: comments,
  }, {
    x: 'Workchat',
    y: chats,
  }];

  const postPercent = ((posts / total) * 100 || 0).toFixed(2);
  const commentPercent = ((comments / total) * 100 || 0).toFixed(2);
  const chatPercent = ((chats / total) * 100 || 0).toFixed(2);

  return (
    <Card title="Workplace by Facebook Events (Last 24 Hours)">
      <Card.Grid style={headerCardStyle}>
        <Charts.Pie
          hasLegend
          title="Events"
          subTitle="Last 24hours"
          data={pieData}
        />
      </Card.Grid>
      <Card.Grid style={cardStyle}>
        <Charts.Pie
          percent={postPercent}
          subTitle="Posts"
          total={`${postPercent}%`}
        />
      </Card.Grid>
      <Card.Grid style={cardStyle}>
        <Charts.Pie
          percent={commentPercent}
          subTitle="Comments"
          total={`${commentPercent}%`}
        />
      </Card.Grid>
      <Card.Grid style={cardStyle}>
        <Charts.Pie
          percent={chatPercent}
          subTitle="Workchat"
          total={`${chatPercent}%`}
        />
      </Card.Grid>
    </Card>
  );
};

export default Home;
