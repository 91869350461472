/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

import {
  checkLogin, login, logout, refreshLogin,
} from '../api';

import useInterval from './useInterval';

const {
  createContext, useContext, useState, useEffect,
} = React;

const AuthContext = createContext();

export const AuthProvider = (props) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState();

  const load = async () => {
    try {
      await checkLogin();
      setIsAuthenticated(true);
      setLoading(false);
    } catch {
      console.log('Not logged in');
      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  useInterval(() => {
    if (!isAuthenticated) {
      return;
    }

    refreshLogin().then(
      () => setIsAuthenticated(true),
      () => setIsAuthenticated(false),
    );
  }, 10 * 60 * 1000);

  const handleLogin = async ({ username, password }) => {
    await login({ username, password });
    setIsAuthenticated(true);
  };

  const handleLogout = async () => {
    await logout();
    setIsAuthenticated(false);
  };

  const value = React.useMemo(() => ({
    loading,
    isAuthenticated,
    handleLogout,
    handleLogin,
  }), [loading, isAuthenticated]);

  return <AuthContext.Provider {...props} value={value} />;
};

export const useAuth = () => useContext(AuthContext);
