import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link, useHistory } from 'react-router-dom';
import {
  Popconfirm, Button, PageHeader, Table, Divider, Icon, message,
} from 'antd';

import White from './White';
import Content from './Content';
import {
  getCase, closeCase, openCase, destroyCaseItem, updateCaseItemNotes, exportCase,
} from '../api';

import CaseEditModal from './CaseEditModal';
import expandedCaseItems from '../utils/expand-case-items';

const routes = [{ breadcrumbName: 'QPS Workplace' }, { breadcrumbName: 'Cases' }];

const getText = (item) => {
  if (item.type === 'comment') {
    return item.message;
  }

  if (item.type === 'post') {
    return item.message;
  }

  if (item.type === 'workchat') {
    return item.message;
  }

  if (item.type === 'security') {
    return item.event;
  }

  if (item.type === 'gap') {
    return item.description;
  }

  if (item.type === 'unknown') {
    return item.message;
  }

  return null;
};

const CaseDetail = ({ match: { params: { id } } }) => {
  const history = useHistory();
  const [dataSource, setDataSource] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedCaseItem, setSelectedCaseItem] = useState({});
  const [note, setNote] = useState('');

  const doSearch = () => {
    getCase(id).then(({ case: caseObj }) => setDataSource(caseObj));
  };

  const handleConfirmRemove = (item) => async () => {
    try {
      await destroyCaseItem(item.id);
      doSearch();
    } catch (err) {
      console.log(err);
      message.error('Could not remove the case item.');
    }
  };

  const handleExportClick = async () => {
    await exportCase(id);
    history.push('/case-exports');
  };

  const handleCaseToggle = async () => {
    if (dataSource.active) {
      await closeCase(dataSource.id);
    } else {
      await openCase(dataSource.id);
    }
    doSearch();
  };

  useEffect(() => {
    doSearch();
  }, []);

  const handleEditNote = (caseItem) => () => {
    setVisible(true);
    setSelectedCaseItem(caseItem);
    setNote(caseItem.notes);
  };

  const handleCancel = () => {
    setVisible(false);
    setSelectedCaseItem(undefined);
    setNote(undefined);
  };

  const handleSubmit = async () => {
    handleCancel();
    try {
      await updateCaseItemNotes(selectedCaseItem.id, note);
      message.success('Updated successfully');
      await doSearch();
    } catch (err) {
      console.log(err);
      message.error('Could not update case notes.');
    }
  };

  const columns = [
    { title: 'Type', key: 'type', dataIndex: 'type' },
    { title: 'Verb', key: 'verb', dataIndex: 'verb' },
    {
      title: 'Created', key: 'created', dataIndex: 'createdAt',
    },
    {
      title: 'Text', key: 'message', dataIndex: 'message', render: (_, item) => getText(item),
    },
    {
      title: 'Notes', key: 'notes', dataIndex: 'notes', render: (n) => n,
    },
    {
      title: '',
      key: 'options',
      dataIndex: 'id',
      render: (_, item) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Button type="link" onClick={handleEditNote(item)}>
            <Icon type="edit" />
          </Button>
          <Divider type="vertical" />
          {(item.type !== 'gap') && (
            <>
              <Link to={`/${item.type}/${item.detailId}`}>Details</Link>
              <Divider type="vertical" />
            </>
          )}
          <Popconfirm
            title="Are you sure delete this case item?"
            onConfirm={handleConfirmRemove(item)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Remove from case</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  if (!dataSource) {
    return null;
  }

  const {
    CaseItems: caseItems,
    active,
  } = dataSource;

  return (
    <>
      <White>
        <PageHeader
          title="Case Detail"
          breadcrumb={{ routes }}
          extra={[
            <Button
              type="secondary"
              onClick={() => handleExportClick()}
              key="export-case"
            >
              Export Case
            </Button>,
            <Button
              type="primary"
              onClick={handleCaseToggle}
              key="case"
            >
              {active ? 'Close case' : 'Open case'}
            </Button>,
          ]}
        />
      </White>
      <Content>
        <Table rowKey="id" columns={columns} dataSource={expandedCaseItems(caseItems)} loading={!caseItems} />
      </Content>
      <CaseEditModal
        visible={visible}
        note={note}
        setNote={setNote}
        onCancel={handleCancel}
        onOk={handleSubmit}
      />
    </>
  );
};

CaseDetail.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
};

export default withRouter(CaseDetail);
