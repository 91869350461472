/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from 'react';
import debounce from 'lodash/debounce';

import { Select, Spin } from 'antd';

import { getGroups } from '../api';

const { Option } = Select;

const options = (groups) => groups.map(({
  id, name,
}) => (
  <Option value={id} key={id}>{name}</Option>
));

const GroupSelect = (props) => {
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);

  const fetchGroup = debounce((search) => {
    setFetching(true);
    getGroups({ search }).then((apiGroups) => {
      setData(apiGroups.groups);
      setFetching(false);
    });
  }, 300);

  return (
    <Select
      {...props}
      showSearch
      filterOption={false}
      notFoundContent={fetching ? <Spin size="small" /> : <div>No group found</div>}
      onSearch={fetchGroup}
    >
      { options(data) }
    </Select>
  );
};

export default GroupSelect;
