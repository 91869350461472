const getLatestMessage = (events) => {
  const event = events.find(({ verb }) => ['edit', 'create'].includes(verb));

  if (event) {
    return event.message;
  }

  return null;
};

export default getLatestMessage;
