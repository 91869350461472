import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu, Icon } from 'antd';
import PropTypes from 'prop-types';

import pkg from '../../../package.json';

import './Layout.css';

import qpslogo from '../assets/qpslogo.png';

const { Header, Sider, Content } = Layout;

const Component = ({ children, location }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => setCollapsed(!collapsed);

  const selectedKeys = () => {
    if (location.pathname.indexOf('/logout') > -1) return ['8'];
    if (location.pathname.indexOf('/gaps') > -1) return ['7'];
    if (location.pathname.indexOf('/cases') > -1) return ['6'];
    if (location.pathname.indexOf('/case-exports') > -1) return ['9'];
    if (location.pathname.indexOf('/unknown') > -1) return ['5'];
    if (location.pathname.indexOf('/security') > -1) return ['4'];
    if (location.pathname.indexOf('/workchat') > -1) return ['3'];
    if (location.pathname.indexOf('/search') > -1) return ['2'];
    return ['1'];
  };

  return (
    <Layout className="primary-layout">
      <Sider trigger={null} collapsible collapsed={collapsed} className="side-navigation" width="256">
        <div className="logo">
          <img src={qpslogo} alt="Logo" />
          <h1>Workplace</h1>
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={selectedKeys()}>
          <Menu.Item key="1">
            <Icon type="home" />
            <span>Home</span>
            <Link to="/" />
          </Menu.Item>
          <Menu.Item key="2">
            <Icon type="search" />
            <span>Search</span>
            <Link to="/search" />
          </Menu.Item>
          <Menu.Item key="3">
            <Icon type="message" />
            <span>Work Chat</span>
            <Link to="/workchat" />
          </Menu.Item>
          <Menu.Item key="4">
            <Icon type="security-scan" />
            <span>Security</span>
            <Link to="/security" />
          </Menu.Item>
          <Menu.Item key="5">
            <Icon type="question-circle" />
            <span>Unknown</span>
            <Link to="/unknown" />
          </Menu.Item>
          <Menu.Item key="6">
            <Icon type="container" />
            <span>Cases</span>
            <Link to="/cases" />
          </Menu.Item>
          <Menu.Item key="9">
            <Icon type="export" />
            <span>Case Exports</span>
            <Link to="/case-exports" />
          </Menu.Item>
          <Menu.Item key="7">
            <Icon type="warning" />
            <span>Gaps</span>
            <Link to="/gaps" />
          </Menu.Item>
          <Menu.Item key="8">
            <Icon type="logout" />
            <span>Logout</span>
            <Link to="/logout" />
          </Menu.Item>
        </Menu>
        <span style={{
          position: 'absolute', bottom: 0, left: 10, color: '#777777',
        }}
        >
          v
          {pkg.version}
        </span>
      </Sider>
      <Layout>
        <div className="header-fixed">
          <Header style={{ background: '#fff', padding: 0 }} className="header-box-shadow">
            <Icon
              className="trigger"
              type={collapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={toggle}
            />
          </Header>
        </div>
        <div className="content-fixed">
          <Content>
            {children}
          </Content>
        </div>
      </Layout>
    </Layout>
  );
};

Component.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default withRouter(Component);
