import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';

const { Content } = Layout;

const Component = ({ children }) => (
  <Content
    style={{
      background: '#fff',
      padding: 24,
      margin: 24,
      minHeight: 280,
    }}
  >
    {children}
  </Content>
);

Component.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Component;
