import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  List, Collapse, PageHeader, Descriptions, Tag, Button,
} from 'antd';

import WorkplaceLink from './WorkplaceLink';
import White from './White';
import Content from './Content';
import AttachmentDownload from './AttachmentDownload';
import AttachmentJSONView from './AttachmentJSONView';
import CaseItemAdd from './CaseItemAdd';
import UserName from './UserName';

import { getComment } from '../api';

import getLatestMessage from '../utils/get-latest-message';

const { Panel } = Collapse;

const routes = [{ breadcrumbName: 'QPS Workplace' }, { breadcrumbName: 'Comment Detail' }];

const PostDetail = ({ match: { params: { id } } }) => {
  const [dataSource, setDataSource] = useState();
  const [caseItem, setCaseItem] = useState();

  const handleCaseItemModelOpen = (item) => () => setCaseItem({ id: item.id, type: 'comment' });
  const handleCaseItemModelClose = () => setCaseItem(null);

  useEffect(() => {
    getComment(id).then(({ comment }) => setDataSource(comment));
  }, []);

  if (!dataSource) {
    return null;
  }

  const {
    createdAt: processedAt,
    User: user,
    Post: post,
    Children: children,
    Parent: parent,
    CommentEvents: events,
  } = dataSource;

  const message = getLatestMessage(events);
  const { time } = events.find(({ verb }) => verb === 'create') || {};
  const { createdAt: deletedAt } = events.find(({ verb }) => verb === 'delete') || {};
  const parentList = parent ? [parent] : [];

  // Get the unique attachments from all events
  const attachmentEntries = events
    .flatMap(({ Attachments }) => Attachments)
    .map((attachment) => [attachment.id, attachment]);

  const attachments = [...new Map(attachmentEntries).values()];

  return (
    <>
      <White>
        <PageHeader
          title="Comment Detail"
          breadcrumb={{ routes }}
          extra={[
            <Button
              type="primary"
              onClick={handleCaseItemModelOpen(dataSource)}
              key="case"
            >
              Add to Case
            </Button>,
          ]}
        />
      </White>
      <Content>
        <Descriptions title="Message Information" bordered>
          <Descriptions.Item label="Created Time">{time}</Descriptions.Item>
          <Descriptions.Item label="Processed Time">{processedAt}</Descriptions.Item>
          <Descriptions.Item label="From">
            <UserName id={user && user.id} />
          </Descriptions.Item>
          <Descriptions.Item label="Post">
            <div>
              <a href={`/post/${post.id}`}>Detail</a>
            </div>
            <div>
              <WorkplaceLink href={`/${post.externalId}`}>View on workplace</WorkplaceLink>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="Removed">
            {!deletedAt && <Tag>No</Tag>}
            {
              deletedAt && (
                <>
                  <Tag color="red">Yes</Tag>
                  {` ${deletedAt}`}
                </>
              )
            }
          </Descriptions.Item>
          <Descriptions.Item label="Latest Message" span={3}>{message}</Descriptions.Item>
          <Descriptions.Item label="Parent comment" span={3}>
            {parentList.length > 0 && (
              <List
                bordered
                dataSource={parentList}
                renderItem={(parentItem) => {
                  const parentMessage = getLatestMessage(
                    parentItem.CommentEvents,
                  );

                  return (
                    <List.Item
                      actions={[<a href={`/comment/${parent.id}`}>detail</a>]}
                    >
                      {parentMessage}
                    </List.Item>
                  );
                }}
              />
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Child comments" span={3}>
            {children.length > 0 && (
              <List
                bordered
                dataSource={children}
                renderItem={(child) => {
                  const childMessage = getLatestMessage(child.CommentEvents);

                  return (
                    <List.Item
                      actions={[<a href={`/comment/${child.id}`}>detail</a>]}
                    >
                      {childMessage}
                    </List.Item>
                  );
                }}
              />
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Events" span={3}>
            {events.length === 0
              ? <Tag>None</Tag>
              : (
                <Collapse>
                  { events.map((event) => (
                    <Panel header={event.verb} key={event.id}>
                      <AttachmentJSONView
                        storageRef={event.processedStorageRef}
                      />
                    </Panel>
                  ))}
                </Collapse>
              )}
          </Descriptions.Item>
          <Descriptions.Item label="Attachments" span={3}>
            {attachments.length === 0
              ? <Tag>None</Tag>
              : (
                <Collapse>
                  {
                    attachments.map((a) => (
                      <Panel header={a.name} key={a.id}>
                        <AttachmentDownload
                          errorMessage={a.errorMessage}
                          name={a.name}
                          mimeType={a.mimeType}
                          url={a.url}
                        />
                      </Panel>
                    ))
                  }
                </Collapse>
              )}
          </Descriptions.Item>
        </Descriptions>
        <CaseItemAdd
          visible={Boolean(caseItem)}
          item={caseItem}
          onClose={handleCaseItemModelClose}
        />
      </Content>
    </>
  );
};

PostDetail.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
};

export default withRouter(PostDetail);
