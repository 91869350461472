/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Form, Input } from 'antd';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

class CreateGapForm extends React.Component {
  render() {
    const { form, onSubmit } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form onSubmit={onSubmit}>
        <span>Date Range</span>
        <Form.Item>
          {
            getFieldDecorator('range', {
              rules: [{ required: true, message: 'Please name the Gap' }],
            })(
              <RangePicker showTime={{ format: 'HH:mm' }} />,
            )
          }
        </Form.Item>
        <Form.Item>
          {
            getFieldDecorator('description', {
              rules: [{ required: true, message: 'Please add a description for the gap' }],
            })(
              <TextArea placeholder="Gap description" />,
            )
          }
        </Form.Item>
      </Form>
    );
  }
}

CreateGapForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Form.create({ name: 'createGap' })(CreateGapForm);
