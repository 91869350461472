import React, { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';

import {
  Select, DatePicker, PageHeader, Table, Button, Modal, Divider, Input,
} from 'antd';

import White from './White';
import Content from './Content';
import CasesForm from './CasesForm';
import GapsBanner from './GapsBanner';
import {
  createCase, exportCase, searchCases, searchGapsForBanner,
} from '../api';

import expandedCaseItems from '../utils/expand-case-items';

const { RangePicker } = DatePicker;
const { Option } = Select;

const routes = [{ breadcrumbName: 'QPS Workplace' }, { breadcrumbName: 'Cases' }];

const Cases = ({ history, location }) => {
  const { startDate, endDate, ...parsedQuery } = qs.parse(location.search);
  const { page: initialPage, ...initialFilters } = {
    page: 1,
    active: 'true',
    ...parsedQuery,
    dateRange: startDate && endDate
      ? [moment(startDate), moment(endDate)]
      : undefined,
  };
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [gapDataSource, setGaps] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [pagination, setPagination] = useState({ page: initialPage });
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [, setName] = useState('');

  const ref = createRef();

  const columns = React.useMemo(() => ([
    { title: 'Name', key: 'name', dataIndex: 'name' },
    { title: 'Items', key: 'itemCount', dataIndex: 'itemCount' },
    { title: 'Created', key: 'createdAt', dataIndex: 'createdAt' },
    {
      title: '',
      key: 'actions',
      dataIndex: 'id',
      render: (id, item) => {
        const handleExportClick = async () => {
          await exportCase(id);
          history.push('/case-exports');
        };

        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <Link to={`/cases/${id}`}>Details</Link>
            { item.itemCount > 0 && (
              <>
                <Divider type="vertical" />
                <Button type="link" onClick={handleExportClick}>Export</Button>
              </>
            )}
          </div>
        );
      },
    },
  ]), []);

  const doSearch = async () => {
    const { dateRange, ...params } = { ...filters, ...pagination };

    const parsedDateRange = dateRange && dateRange[0] && dateRange[1]
      ? [dateRange[0].toISOString(), dateRange[1].toISOString()]
      : [];

    history.push({
      pathname: '/cases',
      search: qs.stringify({
        ...params,
        startDate: parsedDateRange[0],
        endDate: parsedDateRange[1],
      }),
    });

    /* eslint-disable-next-line */
    params.startDate = parsedDateRange[0];
    /* eslint-disable-next-line */
    params.endDate = parsedDateRange[1];

    const [
      { results, total: totalCount },
      { gaps },
    ] = await Promise.all([
      searchCases(params),
      searchGapsForBanner(params),
    ]);

    setDataSource(results.map((apiCase) => ({
      ...apiCase,
      createdAt: new Date(apiCase.createdAt).toLocaleString(),
      itemCount: expandedCaseItems(apiCase.CaseItems).length,
    })));
    setGaps(gaps);
    setTotal(totalCount);
    setLoading(false);
  };

  useEffect(() => {
    doSearch();
  }, []);

  const clear = () => {
    const { form } = ref.current.props;
    setTimeout(() => {
      form.resetFields();
      setName('');
      setModalOpen(false);
    }, 250);
  };

  const handleChange = (key) => (value) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleInputChange = (key) => (evt) => {
    setFilters({ ...filters, [key]: evt.target.value });
  };

  const handleFilter = () => {
    doSearch();
  };

  const handleCreate = () => {
    setModalOpen(true);
  };

  const handleReset = () => {
    setFilters({ active: 'true' });
    doSearch();
  };

  const handleTableChange = ({ current }) => {
    setPagination({ page: current });
  };

  const handleOk = (evt) => {
    if (evt) evt.preventDefault();
    const { form } = ref.current.props;
    form.validateFields(async (err, { name }) => {
      if (!err) {
        await createCase(name);
        clear();
        doSearch();
      }
    });
  };

  const handleCancel = () => {
    clear();
  };

  return (
    <>
      <White>
        <PageHeader
          title="Cases"
          subTitle="Group items into a Case then export"
          breadcrumb={{ routes }}
          extra={[<Button type="primary" onClick={handleCreate} key="case">Create Case</Button>]}
        />
      </White>
      <Content>
        <div className="table-fitlers">
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            onChange={handleChange('dateRange')}
            value={filters.dateRange}
          />
          <Input placeholder="Name" style={{ width: 300 }} onChange={handleInputChange('name')} value={filters.name} />

          <Select style={{ minWidth: 200 }} onChange={handleChange('active')} value={filters.active}>
            <Option value="true">Open</Option>
            <Option value="false">Closed</Option>
          </Select>
          <Button type="primary" onClick={handleFilter}>Filter</Button>
          <Button onClick={handleReset}>Reset</Button>
        </div>
        <GapsBanner dataSource={gapDataSource} dateRange={filters.dateRange} />
        <Table
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          onChange={handleTableChange}
          pagination={{ total, defaultCurrent: pagination.page }}
        />
      </Content>
      <Modal
        title="Create Case"
        visible={modalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <CasesForm wrappedComponentRef={ref} onSubmit={handleOk} />
      </Modal>
    </>
  );
};

Cases.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
};

export default withRouter(Cases);
