import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'antd';
import { Link } from 'react-router-dom';

const GapsBanner = ({ dataSource, dateRange }) => {
  if (!dataSource || dataSource.length === 0) {
    return null;
  }

  const searchParams = new URLSearchParams();
  if (dateRange[0]) {
    searchParams.append('startDate', dateRange[0].toISOString());
  }
  if (dateRange[1]) {
    searchParams.append('endDate', dateRange[1].toISOString());
  }

  const description = 'Gaps were recorded in this period. Click here for more information';

  return (
    <Link to={{ pathname: '/gaps', search: searchParams.toString() }}>
      <Alert
        message={description}
        type="info"
        showIcon
        style={{ marginBottom: 16 }}
      />
    </Link>
  );
};

GapsBanner.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.shape(Date)),
};

GapsBanner.defaultProps = {
  dateRange: [new Date(0), new Date()],

};

export default GapsBanner;
