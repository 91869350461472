export default (caseitems) => (caseitems || [])
  .map((item) => {
    if (item.Comment) {
      return item.Comment.CommentEvents.map((event) => ({
        ...event,
        id: item.id,
        detailId: event.CommentId,
        caseId: item.caseId,
        notes: item.notes,
        type: 'comment',
      }));
    }

    if (item.Post) {
      return item.Post.PostEvents.map((event) => ({
        ...event,
        id: item.id,
        detailId: event.PostId,
        caseId: item.caseId,
        notes: item.notes,
        type: 'post',
      }));
    }

    if (item.WorkchatMessage) {
      return item.WorkchatMessage.WorkchatMessageEvents.map((event) => ({
        ...event,
        id: item.id,
        detailId: event.WorkchatMessageId,
        caseId: item.caseId,
        notes: item.notes,
        type: 'workchat',
      }));
    }

    if (item.Gap) {
      return {
        ...item,
        detailId: item.GapId,
        type: 'gap',
      };
    }

    if (item.Security) {
      return {
        ...item,
        detailId: item.SecurityId,
        type: 'security',
      };
    }

    if (item.Unknown) {
      return {
        ...item.Unknown,
        ...item,
        detailId: item.UnknownId,
        type: 'unknown',
      };
    }

    return undefined;
  })
  .filter(Boolean)
  .flat();
