/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select } from 'antd';

import { searchCases } from '../api';

const { Option } = Select;

class CaseItemAddForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.doSearch();
  }

  async doSearch() {
    const { results } = await searchCases();
    this.setState({ dataSource: results, loading: false });
  }

  render() {
    const { loading, dataSource } = this.state;
    const { form, onSubmit } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form onSubmit={onSubmit}>
        <Form.Item>
          {
            getFieldDecorator('caseId', {
              rules: [{ required: true, message: 'Please select a Case' }],
            })(
              <Select placeholder="Case Name" autoFocus loading={loading}>
                {
                  dataSource.map((o) => (
                    <Option key={o.id} value={o.id}>{o.name}</Option>
                  ))
                }
              </Select>,
            )
          }
        </Form.Item>
        <Form.Item>
          {
            getFieldDecorator('notes', {})(
              <Input.TextArea placeholder="Notes" rows={6} />,
            )
          }
        </Form.Item>
      </Form>
    );
  }
}

CaseItemAddForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Form.create({ name: 'createCaseAddForm' })(CaseItemAddForm);
