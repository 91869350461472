import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';

import {
  PageHeader, Table, Select, DatePicker, Button, Divider,
} from 'antd';

import White from './White';
import Content from './Content';
import CaseItemAdd from './CaseItemAdd';
import GapsBanner from './GapsBanner';
import UserSelect from './UserSelect';
import UserName from './UserName';
import {
  searchGapsForBanner, searchSecurity, getSecurityEvents,
} from '../api';

const { Option } = Select;
const { RangePicker } = DatePicker;

const routes = [{ breadcrumbName: 'QPS Workplace' }, { breadcrumbName: 'Security' }];

const Security = ({ history, location }) => {
  const { startDate, endDate, ...parsedQuery } = qs.parse(location.search);
  const { page: initialPage, ...initialFilters } = {
    page: 1,
    ...parsedQuery,
    dateRange: startDate && endDate
      ? [moment(startDate), moment(endDate)]
      : undefined,
  };
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [caseItem, setCaseItem] = useState();
  const [gapDataSource, setGaps] = useState([]);
  const [pagination, setPagination] = useState({ page: initialPage });
  const [total, setTotal] = useState(0);

  const handleCaseItemModelOpen = (item) => () => setCaseItem({ id: item.id, type: 'security' });
  const handleCaseItemModelClose = () => setCaseItem(null);

  const doSearch = async () => {
    const { dateRange, ...params } = { ...filters, ...pagination };

    const parsedDateRange = dateRange && dateRange[0] && dateRange[1]
      ? [dateRange[0].toISOString(), dateRange[1].toISOString()]
      : [];

    history.push({
      pathname: '/security',
      search: qs.stringify({
        ...params,
        startDate: parsedDateRange[0],
        endDate: parsedDateRange[1],
      }),
    });

    /* eslint-disable-next-line */
    params.startDate = parsedDateRange[0];
    /* eslint-disable-next-line */
    params.endDate = parsedDateRange[1];

    const [
      { results, total: totalCount },
      { gaps },
    ] = await Promise.all([
      searchSecurity(params),
      searchGapsForBanner(params),
    ]);
    setGaps(gaps);
    setDataSource(results);
    setTotal(totalCount);
    setLoading(false);
  };

  const handleChange = (key) => (value) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleReset = () => {
    setFilters({});
    doSearch();
  };

  const handleFilter = () => {
    doSearch();
  };

  const handleTableChange = ({ current }) => {
    setPagination({ page: current });
  };

  useEffect(() => {
    doSearch();
  }, [pagination]);

  useEffect(() => {
    getSecurityEvents().then(
      ({ events: apiSecurityEvents }) => setEventTypes(apiSecurityEvents),
    );
  }, []);

  const columns = [
    { title: 'Timestamp', key: 'timestamp', dataIndex: 'time' },
    { title: 'Event', key: 'event', dataIndex: 'event' },
    {
      title: 'Actor',
      key: 'actor',
      dataIndex: 'actorId',
      render: (userId) => (
        <UserName
          id={userId}
        />
      ),
    },
    {
      title: 'Target',
      key: 'target',
      dataIndex: 'targetId',
      render: (userId) => (
        <UserName
          id={userId}
        />
      ),
    },
    { title: 'IP', key: 'ip', dataIndex: 'ip' },
    { title: 'Field', key: 'field', dataIndex: 'field' },
    {
      title: '',
      key: 'options',
      dataIndex: 'id',
      render: (id, item) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Link to={`/security/${id}`}>Details</Link>
          <Divider type="vertical" />
          <Button type="link" onClick={handleCaseItemModelOpen(item)}>Add to Case</Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <White>
        <PageHeader
          title="Security"
          subTitle="Explore all Workplace Security events"
          breadcrumb={{ routes }}
        />
      </White>
      <Content>
        <div className="table-fitlers">
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            onChange={handleChange('dateRange')}
            value={filters.dateRange}
          />
          <Select placeholder="Event" style={{ minWidth: 300 }} onChange={handleChange('event')} value={filters.event}>
            {
              eventTypes.map((e) => (
                <Option value={e.event} key={e.event}>{e.event}</Option>
              ))
            }
          </Select>
          <UserSelect placeholder="Actor" style={{ minWidth: 300 }} onChange={handleChange('actor')} value={filters.actor} />
          <UserSelect placeholder="Target" style={{ minWidth: 300 }} onChange={handleChange('target')} value={filters.target} />
          <Button type="primary" onClick={handleFilter}>Filter</Button>
          <Button onClick={handleReset}>Reset</Button>
        </div>
        <GapsBanner dataSource={gapDataSource} dateRange={filters.dateRange} />
        <Table
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          rowKey="id"
          onChange={handleTableChange}
          pagination={{ total, defaultCurrent: pagination.page }}
        />
      </Content>
      <CaseItemAdd
        visible={Boolean(caseItem)}
        item={caseItem}
        onClose={handleCaseItemModelClose}
      />
    </>
  );
};

Security.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
};

export default withRouter(Security);
