/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from 'react';
import debounce from 'lodash/debounce';

import { Select, Spin } from 'antd';

import { useUsers } from './UserContext';

const { Option } = Select;

const options = (users) => users.map(({
  id, externalId, email, name,
}) => {
  let text;

  if (name) {
    text = `${name} (${externalId})`;
  } else if (email) {
    text = `${email} (${externalId})`;
  } else {
    text = `${externalId}`;
  }

  return (
    <Option key={id} value={id}>
      { text }
    </Option>
  );
});

const UserSelect = (props) => {
  const { users, searchUsers } = useUsers();
  const [data, setData] = useState(users);
  const [fetching, setFetching] = useState(false);

  const fetchUser = debounce((text) => {
    if (!text) {
      setData(users);
      setFetching(false);
      return;
    }

    setFetching(true);
    searchUsers(text).then((apiUsers) => {
      setData(apiUsers);
      setFetching(false);
    });
  }, 300);

  return (
    <Select
      {...props}
      showSearch
      filterOption={false}
      notFoundContent={fetching ? <Spin size="small" /> : <div>No user found</div>}
      onSearch={fetchUser}
    >
      { options(data) }
    </Select>
  );
};

export default UserSelect;
