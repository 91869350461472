import React, { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import {
  withRouter,
} from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';

import {
  PageHeader, Table, DatePicker, Button, Modal,
} from 'antd';
import prettyMilliseconds from 'pretty-ms';

import White from './White';
import Content from './Content';
import { createGap, searchGaps } from '../api';
import GapsForm from './GapsForm';
import CaseItemAdd from './CaseItemAdd';

const { RangePicker } = DatePicker;

const routes = [{ breadcrumbName: 'QPS Workplace' }, { breadcrumbName: 'Gaps' }];

const Gaps = ({ history, location }) => {
  const { startDate, endDate, ...parsedQuery } = qs.parse(location.search);
  const { page: initialPage, ...initialFilters } = {
    page: 1,
    types: ['post', 'comment', 'workchat'],
    verbs: ['create', 'edit', 'delete'],
    ...parsedQuery,
    dateRange: startDate && endDate
      ? [moment(startDate), moment(endDate)]
      : undefined,
  };
  const [filters, setFilters] = useState(initialFilters);
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [caseItem, setCaseItem] = useState();
  const [pagination, setPagination] = useState({ page: initialPage });

  const handleCaseItemModelOpen = (item) => () => setCaseItem({ id: item.id, type: 'gap' });
  const handleCaseItemModelClose = () => setCaseItem(null);

  const ref = createRef();

  const doSearch = async () => {
    const { dateRange, ...params } = { ...filters, ...pagination };

    const parsedDateRange = dateRange && dateRange[0] && dateRange[1]
      ? [dateRange[0].toISOString(), dateRange[1].toISOString()]
      : [];

    history.push({
      pathname: '/gaps',
      search: qs.stringify({
        ...params,
        startDate: parsedDateRange[0],
        endDate: parsedDateRange[1],
      }),
    });

    /* eslint-disable-next-line */
    params.startDate = parsedDateRange[0];
    /* eslint-disable-next-line */
    params.endDate = parsedDateRange[1];

    const { gaps } = await searchGaps({ ...params });
    setLoading(false);
    setDataSource(gaps.map((gap) => ({
      ...gap,
      startDate: new Date(gap.startDate).toLocaleDateString(),
      endDate: new Date(gap.endDate).toLocaleDateString(),
      duration: prettyMilliseconds(
        new Date(gap.endDate).getTime() - new Date(gap.startDate).getTime(),
        { verbose: true },
      ),
    })));
  };

  const handleChange = (key) => (value) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleReset = () => {
    setFilters({});
    doSearch();
  };

  const clear = () => {
    const { form } = ref.current.props;
    setModalOpen(false);
    setTimeout(() => {
      form.resetFields();
    }, 250);
  };

  const handleFilter = () => {
    doSearch();
  };

  const handleCreate = () => {
    setModalOpen(true);
  };

  const handleCancel = () => {
    clear();
  };

  const handleTableChange = ({ current }) => {
    setPagination({ page: current });
  };

  const handleOk = (evt) => {
    if (evt) evt.preventDefault();
    const { form } = ref.current.props;
    form.validateFields(async (err, { range, description }) => {
      if (!err) {
        await createGap({
          description,
          startDate: range[0].toISOString(),
          endDate: range[1].toISOString(),
        });
        clear();
        doSearch();
      }
    });
  };

  useEffect(() => {
    doSearch();
  }, [pagination]);

  const columns = [
    { title: 'Start date', key: 'startDate', dataIndex: 'startDate' },
    { title: 'End date', key: 'endDate', dataIndex: 'endDate' },
    { title: 'Duration', key: 'duration', dataIndex: 'duration' },
    { title: 'Description', key: 'description', dataIndex: 'description' },
    {
      title: '',
      key: 'options',
      dataIndex: 'id',
      render: (id, item) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Button type="link" onClick={handleCaseItemModelOpen(item)}>Add to Case</Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <White>
        <PageHeader
          title="Gaps"
          subTitle="Explore all Workplace Gaps events"
          breadcrumb={{ routes }}
          extra={[<Button type="primary" onClick={handleCreate} key="case">Create Gap</Button>]}
        />
      </White>
      <Content>
        <div className="table-fitlers">
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            onChange={handleChange('dateRange')}
            value={filters.dateRange}
          />
          <Button type="primary" onClick={handleFilter}>Filter</Button>
          <Button onClick={handleReset}>Reset</Button>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource}
          onChange={handleTableChange}
          loading={loading}
          rowKey="id"
        />
      </Content>
      <CaseItemAdd
        visible={Boolean(caseItem)}
        item={caseItem}
        onClose={handleCaseItemModelClose}
      />
      <Modal
        title="Create Gap"
        visible={modalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <GapsForm wrappedComponentRef={ref} onSubmit={handleOk} />
      </Modal>
    </>
  );
};

Gaps.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
};

export default withRouter(Gaps);
