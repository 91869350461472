import React from 'react';
import PropTypes from 'prop-types';

const WorkplaceLink = ({ href, children }) => (
  <a href={`https://my.workplace.com${href}`} target="_blank" rel="noopener noreferrer">{children}</a>
);

WorkplaceLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default WorkplaceLink;
