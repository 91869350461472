{
  "name": "qps-audit",
  "version": "1.2.0",
  "description": "",
  "source": "src/portal/index.html",
  "scripts": {
    "build": "parcel build",
    "package": "node build.mjs",
    "start:server": "tsx watch --ignore ./tmp ./src/backend/development.js",
    "start:frontend": "parcel --port 8080",
    "test": "jest --coverage --coverageReporters=cobertura --coverageReporters=text --coverageReporters=html --runInBand",
    "test:cleanup": "tsx ./post-test-cleanup.js",
    "test:migrations": "tsx ./tests/run-migrations.js",
    "lint": "eslint src lambdas",
    "lambda": "tsx lambdas/run.js"
  },
  "keywords": [],
  "author": "",
  "license": "ISC",
  "dependencies": {
    "@aws-sdk/client-s3": "^3.535.0",
    "@aws-sdk/client-secrets-manager": "^3.535.0",
    "@aws-sdk/client-sqs": "^3.535.0",
    "@aws-sdk/s3-request-presigner": "^3.535.0",
    "@vendia/serverless-express": "^4.10.4",
    "adm-zip": "^0.5.10",
    "ant-design-pro": "^2.3.2",
    "antd": "^3.26.14",
    "archiver": "^5.3.1",
    "axios": "^1.6.1",
    "bluebird": "^3.7.2",
    "body-parser": "^1.19.0",
    "compression": "^1.7.4",
    "cookie-parser": "^1.4.5",
    "cors": "^2.8.5",
    "dataloader": "^2.0.0",
    "dotenv": "^8.2.0",
    "downloadjs": "^1.4.7",
    "express": "^4.18.2",
    "helmet": "^3.21.3",
    "jsonwebtoken": "^9.0.0",
    "jspdf": "^2.5.1",
    "jspdf-autotable": "^3.5.31",
    "lodash": "^4.17.21",
    "mime-types": "^2.1.35",
    "mkdirp": "^1.0.3",
    "moment": "^2.24.0",
    "mysql2": "^2.3.3",
    "passport": "^0.6.0",
    "passport-jwt": "^4.0.0",
    "passport-local": "^1.0.0",
    "passport-oauth2": "^1.5.0",
    "pretty-ms": "^5.1.0",
    "prop-types": "^15.7.2",
    "query-string": "^6.11.1",
    "react": "^16.13.1",
    "react-dom": "^16.13.1",
    "react-router-dom": "^5.1.2",
    "sequelize": "^6.32.1",
    "umzug": "^2.3.0",
    "winston": "^3.3.3"
  },
  "devDependencies": {
    "@babel/preset-env": "^7.24.1",
    "@faker-js/faker": "^8.0.2",
    "@parcel/config-default": "^2.12.0",
    "@parcel/core": "^2.12.0",
    "@types/jest": "^26.0.14",
    "desm": "^1.3.1",
    "esbuild": "^0.20.2",
    "eslint": "^8.45.0",
    "eslint-config-airbnb": "^19.0.4",
    "eslint-plugin-import": "^2.27.5",
    "eslint-plugin-jest": "^27.2.3",
    "eslint-plugin-jsx-a11y": "^6.7.1",
    "eslint-plugin-react": "^7.33.0",
    "eslint-plugin-react-hooks": "^4.6.0",
    "execa": "^8.0.1",
    "fs-extra": "^11.2.0",
    "jest": "^29.7.0",
    "nock": "^13.0.4",
    "parcel": "^2.12.0",
    "process": "^0.11.10",
    "rimraf": "^5.0.1",
    "supertest": "^5.0.0",
    "tsx": "^4.7.1",
    "typescript": "^5.1.6"
  }
}
