import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import qs from 'query-string';

import moment from 'moment';

import {
  PageHeader, Table, Select, DatePicker, Button, Input,
} from 'antd';

import White from './White';
import Content from './Content';
import UserName from './UserName';
import GapsBanner from './GapsBanner';
import UserSelect from './UserSelect';
import GroupSelect from './GroupSelect';

import {
  searchGapsForBanner, search,
} from '../api';

const { Option } = Select;
const { RangePicker } = DatePicker;

const routes = [{ breadcrumbName: 'QPS Workplace' }, { breadcrumbName: 'Search' }];

const Search = ({ history, location }) => {
  const { startDate, endDate, ...parsedQuery } = qs.parse(location.search);
  const { page: initialPage, ...initialFilters } = {
    page: 1,
    types: ['post', 'comment'],
    verbs: ['create', 'edit', 'delete'],
    ...parsedQuery,
    dateRange: startDate && endDate
      ? [moment(startDate), moment(endDate)]
      : [moment().startOf('day').subtract(30, 'days'), moment().endOf('day')],
  };
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [showGroups, setShowGroups] = useState(false);
  const [
    pagination, setPagination,
  ] = useState({ page: parseInt(initialPage, 10) || 1 });
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [gapDataSource, setGaps] = useState([]);

  const doSearch = async () => {
    const { dateRange, ...params } = { ...filters, ...pagination };

    const parsedDateRange = dateRange && dateRange[0] && dateRange[1]
      ? [dateRange[0].toISOString(), dateRange[1].toISOString()]
      : [];

    history.push({
      pathname: '/search',
      search: qs.stringify({
        ...params,
        startDate: parsedDateRange[0],
        endDate: parsedDateRange[1],
      }),
    });

    /* eslint-disable-next-line */
    params.startDate = parsedDateRange[0];
    /* eslint-disable-next-line */
    params.endDate = parsedDateRange[1];

    const [
      { results, total: totalCount },
      { gaps },
    ] = await Promise.all([
      search(params),
      searchGapsForBanner(params),
    ]);
    setLoading(false);
    setGaps(gaps);
    setDataSource(results.map((result) => ({
      ...result,
      time: new Date(result.time).toLocaleString(),
      createdAt: new Date(result.createdAt).toLocaleString(),
      key: `${result.type}-${result.id}`,
    })));
    setTotal(totalCount);
  };

  const handleChange = (key) => (value) => {
    const shouldShowGroups = (key === 'groupId' && value) || (key === 'types' && !value.includes('workchat'));
    const groupId = shouldShowGroups ? filters.groupId : undefined;

    setShowGroups(shouldShowGroups);

    setFilters({ ...filters, groupId, [key]: value });
  };

  const handleInputChange = (key) => (evt) => {
    setFilters({ ...filters, [key]: evt.target.value });
  };

  const handleReset = () => {
    setFilters({
      types: ['post', 'comment'],
      verbs: ['create', 'edit', 'delete'],
    });
    doSearch();
  };

  const handleFilter = () => {
    setShowTable(true);
    doSearch();
  };

  const handleTableChange = ({ current }) => {
    setPagination({ page: current });
  };

  useEffect(() => {
    doSearch();
  }, [pagination]);

  const columns = [
    { title: 'Type', key: 'type', dataIndex: 'type' },
    {
      title: 'Processed', key: 'processed', dataIndex: 'createdAt',
    },
    {
      title: 'Created', key: 'created', dataIndex: 'time',
    },
    {
      title: 'Verb', key: 'verb', dataIndex: 'verb',
    },
    {
      title: 'User',
      key: 'user',
      dataIndex: 'userId',
      render: (userId) => (
        <UserName
          id={userId}
        />
      ),
    },
    {
      title: 'Text',
      key: 'message',
      dataIndex: 'message',
      ellipsis: true,
    },
    {
      title: '',
      key: 'options',
      dataIndex: 'detailId',
      render: (id, item) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Link to={`/${item.type}/${id}`}>Details</Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <White>
        <PageHeader
          title="Search"
          subTitle="Explore Workplace events"
          breadcrumb={{ routes }}
        />
      </White>
      <Content>
        <div className="table-fitlers">
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            onChange={handleChange('dateRange')}
            value={filters.dateRange}
          />
          <Input placeholder="Message" style={{ width: 300 }} onChange={handleInputChange('textSearch')} value={filters.textSearch} />
          <UserSelect placeholder="User" style={{ minWidth: 300 }} onChange={handleChange('userId')} value={filters.userId} />
          {showGroups && (
            <GroupSelect placeholder="Group" style={{ minWidth: 300 }} onChange={handleChange('groupId')} value={filters.groupId} />
          )}
          <Select placeholder="Verbs" mode="multiple" style={{ minWidth: 300 }} onChange={handleChange('verbs')} value={filters.verbs}>
            <Option value="create">Create</Option>
            <Option value="edit">Edit</Option>
            <Option value="delete">Delete</Option>
          </Select>
          <Select placeholder="Type" mode="multiple" style={{ minWidth: 300 }} onChange={handleChange('types')} value={filters.types}>
            <Option value="post">Posts</Option>
            <Option value="comment">Comments</Option>
            <Option value="workchat">Workchat</Option>
            <Option value="unknown">Unknown</Option>
          </Select>
          <Button type="primary" onClick={handleFilter}>Search</Button>
          <Button onClick={handleReset}>Reset</Button>
        </div>
        <GapsBanner dataSource={gapDataSource} dateRange={filters.dateRange} />
        {
          showTable && (
            <Table
              columns={columns}
              dataSource={dataSource}
              onChange={handleTableChange}
              loading={loading}
              pagination={{ total, defaultCurrent: pagination.page }}
            />
          )
        }
      </Content>
    </>
  );
};

Search.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
};

export default withRouter(Search);
