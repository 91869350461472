import React from 'react';
import { PageHeader } from 'antd';

import White from '../White';
import Content from '../Content';
import Table from './Table';

const routes = [{ breadcrumbName: 'QPS Workplace' }, { breadcrumbName: 'Case Exports' }];

export default function CaseExports() {
  return (
    <>
      <White>
        <PageHeader
          title="Case Exports"
          subTitle="Download previously exported cases"
          breadcrumb={{ routes }}
        />
      </White>
      <Content>
        <Table />
      </Content>
    </>
  );
}
