import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import Layout from './Layout';

import { useAuth } from './AuthContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { loading, isAuthenticated } = useAuth();

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          return null;
        }

        return isAuthenticated === true
          ? <Layout><Component {...props} /></Layout>
          : <Redirect to="/login" />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PrivateRoute;
