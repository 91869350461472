import React, { useEffect, useState } from 'react';

import { Redirect } from 'react-router-dom';
import { useAuth } from './AuthContext';

const Logout = () => {
  const [loading, setLoading] = useState(true);
  const { handleLogout } = useAuth();

  useEffect(() => {
    handleLogout().then(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <div>logging out</div>;
  }

  return <Redirect to="/login" />;
};

export default Logout;
