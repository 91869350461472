import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DataLoader from 'dataloader';

import {
  searchUsers as searchUsersFromApi,
  // getUser as getUserFromApi,
  getUsers,
} from '../api';

const UserContext = React.createContext();

const reducer = (state, action) => {
  const newState = new Map(state);
  switch (action.type) {
    case 'add':
      newState.set(action.payload.user.id.toString(), action.payload.user);
      return newState;
    case 'concat':
      action.payload.users.forEach(
        (user) => newState.set(user.id.toString(), user),
      );
      return newState;
    default:
      throw new Error();
  }
};

const userLoader = new DataLoader(
  async (keys) => {
    const { users } = await getUsers(keys);

    return keys
      .map((key) => users
        .find((user) => user.id.toString() === key.toString()));
  },
);

export const UserProvider = ({ children }) => {
  const [users, dispatch] = React.useReducer(reducer, new Map());

  const getUser = (id) => {
    const user = users.get(id.toString());

    if (!user) {
      userLoader.load(id).then((apiUser) => dispatch({
        type: 'add',
        payload: {
          user: apiUser,
        },
      }));
    }

    return user;
  };

  const load = async () => {
    try {
      const apiUsers = await getUser();
      dispatch({
        type: 'concat',
        payload: { users: apiUsers.users },
      });
    } catch {
      // Do nothing
    }
  };

  useEffect(() => {
    load();
  }, []);

  const searchUsers = (text) => searchUsersFromApi(text)
    .then((payload) => {
      dispatch({
        type: 'concat',
        payload,
      });

      return payload.users;
    });

  const value = React.useMemo(() => ({
    users: [...users.values()],
    getUser,
    searchUsers,
  }), [users]);

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUsers = () => React.useContext(UserContext);
