import React from 'react';
import PropTypes from 'prop-types';

export default function Download({ value }) {
  if (!value) return null;

  return (
    <a href={value}>Download</a>
  );
}

Download.propTypes = {
  value: PropTypes.string.isRequired,
};
