/* eslint-disable react/prop-types */
import React from 'react';

import { Redirect } from 'react-router-dom';

import {
  Form, Button, Input, notification,
} from 'antd';

import { useAuth } from '../AuthContext';

import './styles.css';

const LocalLogin = ({ form }) => {
  const { getFieldDecorator } = form;

  const { isAuthenticated, loading, handleLogin } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (validationError, { username, password }) => {
      if (validationError) {
        return;
      }

      handleLogin({ username, password }).catch((error) => {
        notification.error({ message: error.message });
      });
    });
  };

  if (loading) return (<div>Loading</div>);
  if (isAuthenticated) return (<Redirect to="/" />);

  return (
    <div className="layout">
      <div className="auth-wrapper">
        <Form onSubmit={handleSubmit} className="form">
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: 'Username is required' }],
            })(
              <Input
                placeholder="Username"
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Password is required' }],
            })(
              <Input
                type="password"
                placeholder="Password"
              />,
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="submit-button">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Form.create({ name: 'authentication' })(LocalLogin);
