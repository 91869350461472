import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Collapse, PageHeader, Descriptions, Tag, Button,
} from 'antd';

import White from './White';
import Content from './Content';
import AttachmentDownload from './AttachmentDownload';
import AttachmentJSONView from './AttachmentJSONView';
import getLatestMessage from '../utils/get-latest-message';
import CaseItemAdd from './CaseItemAdd';
import Username from './UserName';

import { getWorkchat } from '../api';

const { Panel } = Collapse;

const routes = [{ breadcrumbName: 'QPS Workplace' }, { breadcrumbName: 'Work Chat' }];

const WorkChatDetail = ({ match: { params: { id } } }) => {
  const [dataSource, setDataSource] = useState();
  const [caseItem, setCaseItem] = useState();

  const handleCaseItemModelOpen = (item) => () => setCaseItem({ id: item.id, type: 'workchat' });
  const handleCaseItemModelClose = () => setCaseItem(null);

  useEffect(() => {
    getWorkchat(id).then(({ message }) => setDataSource(message));
  }, []);

  if (!dataSource) {
    return null;
  }

  const {
    createdAt: processedAt,
    User: user,
    Recipients: recipients,
    WorkchatMessageEvents: events,
  } = dataSource;

  const message = getLatestMessage(events);
  const { time } = events.find(({ verb }) => verb === 'create') || {};
  const { createdAt: deletedAt } = events.find(({ verb }) => verb === 'delete') || {};

  // Get the unique attachments from all events
  const attachmentEntries = events
    .flatMap(({ Attachments }) => Attachments)
    .map((attachment) => [attachment.id, attachment]);

  const attachments = [...new Map(attachmentEntries).values()];

  return (
    <>
      <White>
        <PageHeader
          title="Work Chat Detail"
          breadcrumb={{ routes }}
          extra={[
            <Button
              type="primary"
              onClick={handleCaseItemModelOpen(dataSource)}
              key="case"
            >
              Add to Case
            </Button>,
          ]}
        />
      </White>
      <Content>
        <Descriptions title="Message Information" bordered>
          <Descriptions.Item label="Created Time">{time}</Descriptions.Item>
          <Descriptions.Item label="Processed Time">{processedAt}</Descriptions.Item>
          <Descriptions.Item label="From">
            <Username id={user && user.id.toString()} />
          </Descriptions.Item>
          <Descriptions.Item label="Removed">
            {!deletedAt && <Tag>No</Tag>}
            {
              deletedAt && (
                <>
                  <Tag color="red">Yes</Tag>
                  {` ${deletedAt}`}
                </>
              )
            }
          </Descriptions.Item>
          <Descriptions.Item label="To" span={3}>
            {
              recipients.map((recipient) => (
                <Username
                  key={recipient.id}
                  id={recipient.id.toString()}
                  users={[recipient]}
                />
              ))
            }
          </Descriptions.Item>
          <Descriptions.Item label="Message" span={3}>{message}</Descriptions.Item>
          <Descriptions.Item label="Events" span={3}>
            <Collapse>
              {events.map((event) => (
                <Panel header={event.verb} key={event.id}>
                  <AttachmentJSONView
                    storageRef={event.processedStorageRef}
                  />
                </Panel>
              ))}
            </Collapse>
          </Descriptions.Item>
          <Descriptions.Item label="Attachments" span={3}>
            {attachments.length === 0
              ? <Tag>None</Tag>
              : (
                <Collapse>
                  {
                    attachments.map((a) => (
                      <Panel header={a.name} key={a.id}>
                        <AttachmentDownload
                          errorMessage={a.errorMessage}
                          name={a.name}
                          mimeType={a.mimeType}
                          url={a.url}
                        />
                      </Panel>
                    ))
                  }
                </Collapse>
              )}
          </Descriptions.Item>
        </Descriptions>
        <CaseItemAdd
          visible={Boolean(caseItem)}
          item={caseItem}
          onClose={handleCaseItemModelClose}
        />
      </Content>
    </>
  );
};

WorkChatDetail.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
};

export default withRouter(WorkChatDetail);
