import React from 'react';
import { Table as AntdTable, Modal } from 'antd';

import Status from './Status';
import Download from './Download';
import TableDateTime from '../TableDateTime';
import { getCaseExports } from '../../api/cases';

export default function Table() {
  const [loading, setLoading] = React.useState(true);
  const [exports, setExports] = React.useState([]);

  const load = async () => {
    const { data } = await getCaseExports();
    setExports(data);
    setLoading(false);
  };

  const handleErrorClick = (data) => () => {
    Modal.error({
      title: 'Case Export Error',
      content: (<code><pre>{data.errorMessage}</pre></code>),
      width: 768,
    });
  };

  const columns = React.useMemo(() => ([{
    title: 'Export',
    key: 'id',
    dataIndex: 'id',
  }, {
    title: 'Case',
    key: 'caseId',
    dataIndex: 'caseId',
  }, {
    title: 'Name',
    key: 'caseName',
    dataIndex: 'caseName',
  }, {
    title: 'Created',
    key: 'createdAt',
    dataIndex: 'createdAt',
    render: (createdAt) => (<TableDateTime value={createdAt} />),
  }, {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (status, data) => (
      <Status value={status} onErrorClick={handleErrorClick(data)} createdAt={data.createdAt} />
    ),
  }, {
    label: 'Actions',
    title: '',
    render: (data) => (<Download value={data.url} />),
  }]), []);

  React.useEffect(() => {
    load();

    const interval = setInterval(() => { load(); }, 1000 * 15);
    return () => clearInterval(interval);
  }, []);

  return (
    <AntdTable
      rowKey="id"
      columns={columns}
      dataSource={exports}
      loading={loading}
    />
  );
}
