import prettyMilliseconds from 'pretty-ms';

import { post, get } from './fetch';

export const searchGaps = ({ startDate, endDate }) => get('/gaps', { startDate, endDate });

export const searchGapsForBanner = async (params) => {
  const { gaps } = await searchGaps(params);
  return {
    gaps: gaps.map((gap) => ({
      ...gap,
      startDate: new Date(gap.startDate).toLocaleDateString(),
      endDate: new Date(gap.endDate).toLocaleDateString(),
      duration: prettyMilliseconds(
        new Date(gap.endDate).getTime() - new Date(gap.startDate).getTime(),
        { verbose: true },
      ),
    })),
  };
};

export const createGap = ({ description, startDate, endDate }) => post('/gaps', {
  body: { description, startDate, endDate },
});
