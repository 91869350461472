import { post, get, destroy } from './fetch';

export const createCase = async (name) => post('/cases', { body: { name } });

export const getCase = async (id) => get(`/cases/${id}`);

export const getCaseExports = async () => get('/cases/exports');

export const exportCase = async (id) => get(`/cases/${id}/export`);

export const closeCase = async (id) => post(`/cases/${id}`, {
  body: {
    active: false,
  },
});

export const openCase = async (id) => post(`/cases/${id}`, {
  body: {
    active: true,
  },
});

export const createCaseItem = async (
  caseId,
  notes,
  { id, type },
) => post('/case-items', {
  body: {
    caseId, notes, id, type,
  },
});

export const updateCaseItemNotes = async (caseItemId, notes) => post('/case-items/notes', {
  body: { caseItemId, notes },
});

export const destroyCaseItem = async (id) => destroy(`/case-items/${id}`);

export const searchCases = (params) => get('/cases', params);
