/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';
import {
  PageHeader,
  Table,
  Tag,
  Select,
  Button,
  DatePicker,
  Typography,
  Input,
} from 'antd';

import White from './White';
import Content from './Content';
import GapsBanner from './GapsBanner';
import UserSelect from './UserSelect';
import UserName from './UserName';
import {
  searchGapsForBanner, searchWorkchat,
} from '../api';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Paragraph } = Typography;

const routes = [{ breadcrumbName: 'QPS Workplace' }, { breadcrumbName: 'Work Chat' }];

const messageCol = (info) => (
  <Paragraph
    ellipsis={{ rows: 3 }}
    style={{ marginBottom: 0 }}
  >
    {info}
  </Paragraph>
);

const yesNo = (boolean) => {
  if (boolean) return (<Tag color="green">Yes</Tag>);
  return (<Tag>No</Tag>);
};

const WorkChat = ({ history, location }) => {
  const { startDate, endDate, ...parsedQuery } = qs.parse(location.search);
  const { page: initialPage, ...initialFilters } = {
    page: 1,
    verbs: ['create', 'delete'],
    ...parsedQuery,
    dateRange: startDate && endDate
      ? [moment(startDate), moment(endDate)]
      : undefined,
  };
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({ page: initialPage });
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [gapDataSource, setGaps] = useState([]);

  const doSearch = async () => {
    const { dateRange, ...params } = { ...filters, ...pagination };

    const parsedDateRange = dateRange && dateRange[0] && dateRange[1]
      ? [dateRange[0].toISOString(), dateRange[1].toISOString()]
      : [];

    history.push({
      pathname: '/workchat',
      search: qs.stringify({
        ...params,
        startDate: parsedDateRange[0],
        endDate: parsedDateRange[1],
      }),
    });

    /* eslint-disable-next-line */
    params.startDate = parsedDateRange[0];
    /* eslint-disable-next-line */
    params.endDate = parsedDateRange[1];

    const [
      { results, total: totalCount },
      { gaps },
    ] = await Promise.all([
      searchWorkchat(params),
      searchGapsForBanner(params),
    ]);
    setGaps(gaps);
    setDataSource(results.map((message) => ({
      ...message,
      createdAt: new Date(message.createdAt).toLocaleString(),
    })));
    setTotal(totalCount);
    setLoading(false);
  };

  const handleChange = (key) => (value) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleInputChange = (key) => (evt) => {
    setFilters({ ...filters, [key]: evt.target.value });
  };

  const handleReset = () => {
    setFilters({
      verbs: ['create', 'delete'],
    });
    doSearch();
  };

  const handleTableChange = ({ current }) => {
    setPagination({ page: current });
  };

  const handleFilter = () => {
    doSearch();
  };

  useEffect(() => {
    doSearch();
  }, [pagination]);

  const columns = [
    { title: 'Timestamp', key: 'createdAt', dataIndex: 'createdAt' },
    {
      title: 'From',
      key: 'from',
      dataIndex: 'WorkchatMessage',
      render: (WorkchatMessage) => (
        <UserName
          id={WorkchatMessage && WorkchatMessage.userId}
        />
      ),
    },
    {
      title: 'To',
      key: 'to',
      dataIndex: 'WorkchatMessage',
      render: ({ Recipients }) => Recipients.map(
        (messageTo) => (
          <UserName
            key={messageTo.id}
            id={messageTo.id}
          />
        ),
      ),
    },
    {
      title: 'Verb', key: 'verb', dataIndex: 'verb',
    },
    {
      title: 'Message', key: 'message', dataIndex: 'message', render: messageCol,
    },
    {
      title: 'Attachments', key: 'attachments', dataIndex: 'hasAttachments', render: yesNo,
    },
    {
      title: '',
      key: 'options',
      dataIndex: 'WorkchatMessage',
      render: (message) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Link to={`/workchat/${message.id}`}>Details</Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <White>
        <PageHeader
          title="Work Chat Events"
          subTitle="Explore all Work Chat messages events"
          breadcrumb={{ routes }}
        />
      </White>
      <Content>
        <div className="table-fitlers">
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            onChange={handleChange('dateRange')}
            value={filters.dateRange}
          />
          <UserSelect placeholder="From" style={{ minWidth: 300 }} onChange={handleChange('fromId')} value={filters.fromId} />
          <UserSelect placeholder="To" style={{ minWidth: 300 }} onChange={handleChange('toIds')} value={filters.toIds} />
          <Select placeholder="Attachments" style={{ minWidth: 200 }} onChange={handleChange('attachments')} value={filters.attachments}>
            <Option value="true">Yes</Option>
            <Option value="false">No</Option>
          </Select>
          <Input placeholder="Message" style={{ width: 300 }} onChange={handleInputChange('message')} value={filters.message} />
          <Select placeholder="Verb" mode="multiple" style={{ minWidth: 200 }} onChange={handleChange('verbs')} value={filters.verbs}>
            <Option value="create">Create</Option>
            <Option value="delete">Delete</Option>
          </Select>
          <Button type="primary" onClick={handleFilter}>Filter</Button>
          <Button onClick={handleReset}>Reset</Button>
        </div>
        <GapsBanner dataSource={gapDataSource} dateRange={filters.dateRange} />
        <Table
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          rowKey="id"
          onChange={handleTableChange}
          pagination={{ total, defaultCurrent: pagination.page }}
        />
      </Content>
    </>
  );
};

WorkChat.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
};

export default withRouter(WorkChat);
