import React from 'react';
import PropTypes from 'prop-types';

const White = ({ children }) => (
  <div style={{ backgroundColor: '#ffffff' }}>{children}</div>
);

White.propTypes = {
  children: PropTypes.node.isRequired,
};

export default White;
