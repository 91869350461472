import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Collapse, PageHeader, Descriptions, Tag, Button,
} from 'antd';

import White from './White';
import Content from './Content';
import AttachmentDownload from './AttachmentDownload';

import getLatestMessage from '../utils/get-latest-message';
import AttachmentJSONView from './AttachmentJSONView';
import CaseItemAdd from './CaseItemAdd';
import Username from './UserName';

import { getPost } from '../api';

const { Panel } = Collapse;

const routes = [{ breadcrumbName: 'QPS Workplace' }, { breadcrumbName: 'Post Detail' }];

const PostDetail = ({ match: { params: { id } } }) => {
  const [dataSource, setDataSource] = useState();
  const [caseItem, setCaseItem] = useState();

  const handleCaseItemModelOpen = (item) => () => setCaseItem({ id: item.id, type: 'post' });
  const handleCaseItemModelClose = () => setCaseItem(null);

  useEffect(() => {
    getPost(id).then(({ post }) => setDataSource(post));
  }, []);

  if (!dataSource) {
    return null;
  }

  const {
    createdAt: processedAt,
    User: user,
    Group: group,
    Comments: comments,
    PostEvents: events,
  } = dataSource;

  const message = getLatestMessage(events);
  const { time } = events.find(({ verb }) => verb === 'create') || {};
  const { createdAt: deletedAt } = events.find(({ verb }) => verb === 'delete') || {};

  // Get the unique attachments from all events
  const attachmentEntries = events
    .flatMap(({ Attachments }) => Attachments)
    .map((attachment) => [attachment.id, attachment]);

  const attachments = [...new Map(attachmentEntries).values()];

  console.log(attachments);

  return (
    <>
      <White>
        <PageHeader
          title="Post Detail"
          breadcrumb={{ routes }}
          extra={[
            <Button
              type="primary"
              onClick={handleCaseItemModelOpen(dataSource)}
              key="case"
            >
              Add to Case
            </Button>,
          ]}
        />
      </White>
      <Content>
        <Descriptions title="Message Information" bordered>
          <Descriptions.Item label="Created Time">{time}</Descriptions.Item>
          <Descriptions.Item label="Processed Time">{processedAt}</Descriptions.Item>
          <Descriptions.Item label="From">
            <Username id={user && user.id} />
          </Descriptions.Item>
          <Descriptions.Item label="Group">
            <div>{group.name}</div>
          </Descriptions.Item>
          <Descriptions.Item label="Removed">
            {!deletedAt && <Tag>No</Tag>}
            {
              deletedAt && (
                <>
                  <Tag color="red">Yes</Tag>
                  {` ${deletedAt}`}
                </>
              )
            }
          </Descriptions.Item>
          <Descriptions.Item label="Latest Message" span={3}>{message}</Descriptions.Item>
          <Descriptions.Item label="Events" span={3}>
            {events.length === 0
              ? <Tag>None</Tag>
              : (
                <Collapse>
                  {events.map((event) => (
                    <Panel header={event.verb} key={event.id}>
                      <AttachmentJSONView
                        storageRef={event.processedStorageRef}
                      />
                    </Panel>
                  ))}
                </Collapse>
              )}
          </Descriptions.Item>
          <Descriptions.Item label="Attachments" span={3}>
            {attachments.length === 0
              ? <Tag>None</Tag>
              : (
                <Collapse>
                  {
                    attachments.map((a) => (
                      <Panel header={a.name} key={a.id}>
                        <AttachmentDownload
                          errorMessage={a.errorMessage}
                          name={a.name}
                          mimeType={a.mimeType}
                          url={a.url}
                        />
                      </Panel>
                    ))
                  }
                </Collapse>
              )}
          </Descriptions.Item>
          <Descriptions.Item label="Comments" span={3}>
            {comments.length === 0
              ? <Tag>None</Tag>
              : (
                <Collapse>
                  {
                    comments.map((a) => (
                      <Link to={`/comment/${a.id}`}>Comment</Link>
                    ))
                  }
                </Collapse>
              )}
          </Descriptions.Item>
        </Descriptions>
        <CaseItemAdd
          visible={Boolean(caseItem)}
          item={caseItem}
          onClose={handleCaseItemModelClose}
        />
      </Content>
    </>
  );
};

PostDetail.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
};

export default withRouter(PostDetail);
